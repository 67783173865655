import React from 'react'
import Layout from '../../Components/Layout/Layout'
import { NavLink } from 'react-router-dom';
import cimg from '../../img/banner/common-banner.png'
import img from './faq.jpg'
function TermsConditions () {
  const sectionStyle = {
    background: `url(${cimg}) no-repeat`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height : "100%"

  };
  return (
    <>
      <Layout>
        <main>
        <section id="common_banner" style={sectionStyle}>
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="common_bannner_text">
                        <h2>Terms & Conditions</h2>
                        <ul>
                        <li><NavLink to="/">Home</NavLink></li>
                            <li><span><i class="fas fa-circle"></i></span>Terms & Conditions</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
        </main>
        <div className='container'>
          <div className='row mt-3 text-center '>
            <h3>Terms & Conditions</h3>
          </div>
          <div className='row mt-4 '>
            <p className='FontSize15'>
              The following terms and conditions govern the rules and
              regulations for using the Hashim Travel Limited website
              (DarRiazTravel.com). By accessing this website or using any part
              of it, you agree to abide by these terms of use. If you do not
              wish to accept these terms, you should refrain from accessing or
              using the Hashim Travel Limited website. Please note that these
              terms may be modified or updated at any time without prior notice.
              By continuing to access and use the Hashim Travel Limited
              website, you agree to be bound by the most current version of
              these terms. We recommend checking these conditions periodically
              to stay informed about any changes.
            </p>
          </div>
          <div className='row mt-4 '>
            <h4>1-General Terms & Conditions</h4>
            <ul >
              <li style={{listStyle:"square"}} className='my-3' >
                <p>
                  The Customer is prohibited from copying, transferring, reusing
                  without modifications, reusing with modifications any of the
                  mentioned above content published in the website.
                </p>
              </li>

              <li style={{listStyle:"square"}}  className='my-3'>
                <p>
                  Hashim Travel Limited reserves all the rights of the content
                  published on Hashim Travel Limited platform including but
                  not limited to: Texts, Images, icons, logos, trademarks ,
                  video/audio media , links published in the platform.
                </p>
              </li>

              <li style={{listStyle:"square"}}  className='my-3'>
                <p>
                  Hashim Travel Limited grants the customer a limited,
                  non-transferable license to use this site in line with the
                  terms and conditions. The User may only use this site to
                  create authentic reservations or purchases and shall not use
                  this site for any other purposes.
                </p>
              </li>

              <li style={{listStyle:"square"}}  className='my-3'>
                <p>
                  You hereby agree that, as The Customer, shall not at any time
                  distribute, resell, cross-sell, or permit access to the
                  Services to any third party, permit multiple end users to
                  access the Services using shared login credentials (i.e., a
                  shared email address ) and the terms contained in this
                  Agreement.
                </p>
              </li>

              <li style={{listStyle:"square"}}  className='my-3'>
                <p>
                  Hashim Travel Limited reserves the right, at its sole
                  discretion, to suspend The Customer ability to use or access
                  the Services fully or partially at any time while The Platform
                  inspects complaints about violating this Agreement, or for any
                  other reason. Further, it will also have the right to
                  terminate or restrict The Customer use of the Services if the
                  platform, learns that The Customer is misusing the Services in
                  any manner whatsoever.
                </p>
              </li>

              <li style={{listStyle:"square"}}  className='my-3'>
                <p>
                  The Customer holds responsibility for the accuracy of any
                  data, requested by the website online forms or support team,
                  and submitted via different communications channels of online
                  website forms, email messages, social media or direct phone
                  calls.
                </p>
              </li>

              <li style={{listStyle:"square"}}  className='my-3'>
                <p>
                  Hashim Travel Limited also retains full rights to change/
                  add or edit any part or whole of Terms and Conditions at its
                  discretion.
                </p>
              </li>
            </ul>{' '}
          </div>
        </div>
      </Layout>
    </>
  )
}

export default TermsConditions

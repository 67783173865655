import React, { useState, useEffect } from 'react'
import bgimage from '../../Images/NewTemplate/slider-bg1.png'
import Layout from '../../Components/Layout/Layout'
import cimg from '../../img/banner/common-banner.png'
import { NavLink } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faDollar, faCar } from '@fortawesome/free-solid-svg-icons'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { useSelector } from 'react-redux'
import { RangeSlider } from 'rsuite'
import TransferCard from '../../Components/Transfer/TransferCard'
import TransferNew from '../../Components/SearchBar/TransferNew'

function TransferListing () {
  var minValue1 = 0
  var maxValue1 = 0
  const CurrencyRates = useSelector(state => state.hotels.Currency)
  const GBPCurrencyRates = useSelector(state => state.hotels.AllCurrency)
  const TransferDetail = useSelector(state => state.hotels.TrSeResponse)
  if (TransferDetail?.transfers_list !== 0) {
    var amounts = TransferDetail?.transfers_list.map(
      item => item.total_fare_markup
    )
    if (amounts.length !== 0) {
      minValue1 = Math.min(...amounts)
      maxValue1 = Math.max(...amounts)
    }
  }
  const [minValue, setMinValue] = useState(minValue1)
  const [showModifySearch, setShowModifySearch] = useState(false)
  const [showPriceFilter, setShowPriceFilter] = useState(false)
  const [showClassFilter, setShowClassFilter] = useState(false)
  const [maxValue, setMaxValue] = useState(maxValue1)
  const [SessionID, setSessionID] = useState(TransferDetail?.sessionID)
  const [rangeValue, setRangeValue] = useState([minValue1, maxValue1])
  const [FilterData, setFilterData] = useState(TransferDetail?.transfers_list)
  const [vehicleClass, setVehicleClass] = useState([])
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([])
  const handleChange = newRangeValue => {
    setRangeValue(newRangeValue)
    setMinValue(newRangeValue[0])
    setMaxValue(newRangeValue[1])
  }

  useEffect(() => {
    setFilterData(TransferDetail?.transfers_list)
  }, [TransferDetail])

  const handleCheckboxChange = event => {
    const { name, checked } = event.target
    if (checked) {
      setSelectedCheckboxes([...selectedCheckboxes, name])
    } else {
      setSelectedCheckboxes(selectedCheckboxes.filter(item => item !== name))
    }
  }

  const filterbyPrice = () => {
    setShowPriceFilter(false)
    const filteredTours = TransferDetail?.transfers_list.filter(item => {
      const price = item.total_fare_markup
      return price >= minValue && price <= maxValue
    })
    setFilterData(filteredTours)
  }

  const filterbyClass = () => {
    setShowClassFilter(false)
    if (selectedCheckboxes.length !== 0) {
      const filtered = TransferDetail?.transfers_list.filter(item =>
        selectedCheckboxes.includes(item.vehicle_Name)
      )
      setFilterData(filtered)
    } else {
      setFilterData(TransferDetail?.transfers_list)
    }
  }

  useEffect(() => {
    VehicleClassfilter()
  }, [TransferDetail])

  const VehicleClassfilter = () => {
    const VehicleCount = {}
    for (const vehicle of TransferDetail?.transfers_list) {
      const vehicleType = vehicle.vehicle_Name
      if (vehicleType !== undefined) {
        if (VehicleCount.hasOwnProperty(vehicleType)) {
          VehicleCount[vehicleType]++
        } else {
          VehicleCount[vehicleType] = 1
        }
      }

      setVehicleClass(VehicleCount)
    }
  }

  const ShowSearchBar = () => {
    setShowModifySearch(!showModifySearch)
  }

  const renderPrice = price => {
    if (CurrencyRates === undefined) {
      var baseprice = price
    } else {
      const conversionrate =
        GBPCurrencyRates.conversion_rates[CurrencyRates.selectedcurrency]
      var newprice = (Number(conversionrate) * Number(price)).toFixed(0)
      var baseprice = newprice
    }
    return baseprice
  }

  const TogglePriceFilter = () => {
    setShowPriceFilter(!showPriceFilter)
  }

  const ToggleClassFilter = () => {
    setShowClassFilter(!showClassFilter)
  }
  const sectionStyle = {
    background: `url(${cimg}) no-repeat`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height : "100%"

  }; 
  return (
    <>
      <Layout>
        <Modal isOpen={showPriceFilter} toggle={TogglePriceFilter}>
          <ModalHeader toggle={TogglePriceFilter}>Price Filter</ModalHeader>
          <ModalBody>
            <div className='widget widget_price_filter'>
              <div className='mb-0'>
                <RangeSlider
                  value={rangeValue}
                  onChange={handleChange}
                  min={minValue1 - 1}
                  tooltip={false}
                  max={maxValue1 + 1}
                  step={1}
                />
                <div className='pt-2'>
                  <div className='fw-bold mb-2'>
                    Min:{' '}
                    <span id='kt_slider_basic_min'>
                      {renderPrice(minValue)}
                    </span>
                  </div>
                  <div className='fw-bold mb-2'>
                    Max:{' '}
                    <span id='kt_slider_basic_max'>
                      {renderPrice(maxValue)}
                    </span>
                  </div>
                </div>
                <button
                  onClick={filterbyPrice}
                  className='btn select-styling search-btn1 mb-1'
                >
                  Filter
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Modal isOpen={showClassFilter} toggle={ToggleClassFilter}>
          <ModalHeader toggle={ToggleClassFilter}>Vehicles</ModalHeader>
          <ModalBody>
            <div className='widget widget_has_radio_checkbox'>
              <ul>
                {Object.entries(vehicleClass).map(([code, count]) => (
                  <li key={code}>
                    <label>
                      <input
                        type='checkbox'
                        className='custom-textbox'
                        checked={selectedCheckboxes.includes(code)} // Check if checkbox is selected
                        onChange={handleCheckboxChange}
                        name={code}
                        value={code}
                      />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        {' '}
                        {code} ({count})
                      </span>
                    </label>
                  </li>
                ))}
              </ul>
              <button
                onClick={filterbyClass}
                className='btn select-styling search-btn1 mb-1'
              >
                Filter
              </button>
            </div>
          </ModalBody>
        </Modal>


        
        <main>
        <section id="common_banner" style={sectionStyle}>
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="common_bannner_text">
                        <h2>Transfer Search Results</h2>
                        <ul>
                        <li><NavLink to="/">Home</NavLink></li>
                            <li><span><i class="fas fa-circle"></i></span>Transfer</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
     




    <section id="explore_area" class="section_padding">
        <div class="container">
            {/* <!-- Section Heading --> */}
          
            <div class="row">
                <div class="col-lg-3">
                    <div class="left_side_search_area">
                        
                       
                        <div class="left_side_search_boxed">
                            <div class="left_side_search_heading">
                                <h5>Filter by price</h5>
                            </div>
                            <RangeSlider
                            value={rangeValue}
                            onChange={handleChange}
                            min={minValue1 - 1}
                            tooltip={false}
                            max={maxValue1 + 1}
                            step={1}
                          />
                <div className="pt-2">
                <div className="fw-bold mb-2">
                    Min: <span id="kt_slider_basic_min">  {renderPrice(minValue)}{' '} </span>
                  </div>
                  <div className="fw-bold ">
                    Max: <span id="kt_slider_basic_max"> {renderPrice(maxValue)}</span>
                  </div>
                </div>
                <button
                             
                                onClick={filterbyPrice}
                                                             type='submit'
                  class="btn btn_theme btn_md w-100 mt-2 "
                >
                  APPLY
                </button>
                            {/* <button class="apply" type="button">Apply</button> */}
                        </div>
                       
                      
                        <div class="left_side_search_boxed">
                            <div class="left_side_search_heading">
                                <h5>Vehicles</h5>
                            </div>
                            <div class="tour_search_type">
                              
                                {Object.entries(vehicleClass).map(([code, count]) => (
                            <div className='form_check' key={code}>
                                <label class='container_check'>
                                  <input
                                  className='form-check-input'
                                    type='checkbox'
                                    name={code}
                                    value={code}
                                    checked={selectedCheckboxes.includes(code)}
                                    onChange={handleCheckboxChange}
                                  />{' '}
                                  {code} ({count})
                                  <span class='checkmark'></span>
                                </label>
                            </div>
                           ))}
                              
                             
                              
                               
                              
                                <button
                   onClick={filterbyClass}
                        type='submit'
                    class="btn btn_theme btn_md  w-100 mt-2 "
                  >
                    APPLY
                  </button>
                              
                            </div>
                        </div>
                      
                    </div>
                </div>


                <div class="col-lg-9">
                <TransferCard TransferData={FilterData} sessionID={SessionID} />
                </div>
            </div>
        </div>
    </section>
        </main>
      </Layout>
    </>
  )
}

export default TransferListing

import React,{useState,useEffect} from 'react'
import alaqsa from '../../Images/Packages/AlAqsa-package.jpg'
import turky from '../../Images/Packages/Turky-packages.jpg'
import umrah from '../../Images/Packages/umrah-package.jpg'
import Axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faHeadset,
  faPersonWalkingLuggage,
  faSackDollar,
  faUmbrellaBeach,
  faUser
} from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import { Hotelapitoken,ApiEndPoint } from '../GlobalData/GlobalData'

function MorePackages () {
  const [t,i18n] = useTranslation();
  const language=i18n.language;
  const [packages,setPackages]=useState([]);
  useEffect(()=>{
    GetPackages();
  },[]);

  const GetPackages = async () => {
    var token = Hotelapitoken();
    var apiendpoint=ApiEndPoint();
    var data = {
      token: token
    }
    try {
      const response = await Axios.post(
        apiendpoint + '/api/latest_packages_Live',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      var packagesCount;
      if (response.data.latest_packages.length !== 0) {
        var umrah = response.data.latest_packages[0].length
        var alaqsa = response.data.latest_packages[1].length
        var turkey = response.data.latest_packages[2].length
        packagesCount = {
          umrahs: umrah,
          alaqsas: alaqsa,
          turkeys: turkey
        }
      }
      setPackages(packagesCount);
    
    } catch (error) {
      console.error('Error:', error)
    }
  };

  return (
    <>
    


      {/* <div className='container '>
        <div className='row'>
          <div className='section-title'>
            {language==='arabic' ?(
               <h2 className='wow animate__animated animate__fadeInUp' data-wow-duration="1s" data-wow-delay="0.3s">
               {t('More than 100 Packages')}
             </h2>
            ):(
              <h2 className='wow animate__animated animate__fadeInUp' data-wow-duration="1s" data-wow-delay="0.3s">
              More than <span>100 Packages</span>
            </h2>
            )}
          </div>
        </div>
        <div className='grid-wrapper'>
          <div className='wow animate__animated animate__slideInLeft wide image-container morepackages-image-container' data-wow-duration="1s" data-wow-delay="0.3s">
            <img src={alaqsa} alt='' />
            <div className='overlay'>
              {' '}
              <div>
                <h5>{t('Al-Aqsa')}</h5>
              </div>
              <div className='bottom-right'>
                <h5>{t('Available Packages')} {packages.alaqsas}</h5>
              </div>
            </div>
          </div>

          <div className='wow animate__animated animate__slideInRight tall image-container morepackages-image-container'data-wow-duration="1s" data-wow-delay="0.3s" >
            <img src={umrah} alt='' />
            <div className='overlay'>
              {' '}
              <div>
                <h5>{t('Umrah Packages')}</h5>
              </div>
              <div className='bottom-right'>
                <h5>{t('Available Packages')} {packages.umrahs}</h5>
              </div>
            </div>
          </div>

          <div className='wow animate__animated animate__slideInLeft wide image-container morepackages-image-container' data-wow-duration="1s" data-wow-delay="0.3s">
            <img src={turky} alt='' />
            <div className='overlay'>
              {' '}
              <div>
                <h5>{t('Turkey')}</h5>
              </div>
              <div className='bottom-right'>
                <h5>{t('Available Packages')} {packages.turkeys}</h5>
              </div>
            </div>
          </div>
        </div>
      </div> */}



      <section id="go_beyond_area" class="section_padding_top ">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                <div class="heading_left_area">
                  <h2>
                    Go beyond your <span>imagination</span>
                  </h2>
                  <h5>Discover your ideal experience with us</h5>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                <div class="imagination_boxed">
                  <a>
                    <img src={alaqsa} alt="img" />
                  </a>
                  <h3>
                    <a className="text-light">
                    {/* {t('Available Packages')} {packages.alaqsas} */}
                    <span>{t('Al-Aqsa')}</span>
                    </a>
                  </h3>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                <div class="imagination_boxed">
                  <a>
                    <img src={umrah} alt="img" />
                  </a>
                  <h3>
                    <a  className="text-light">
                    {/* {t('Available Packages')} {packages.umrahs}  */}
                    <span>{t('Umrah Packages')}</span>
                    </a>
                  </h3>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                <div class="imagination_boxed">
                  <a>
                    <img src={turky} alt="img" />
                  </a>
                  <h3>
                    <a className="text-light">
                    {/* {t('Available Packages')} {packages.turkeys}  */}
                    <span>{t('Turkey')}</span>
                    </a>
                  </h3>
                </div>
              </div>
              
            </div>
          </div>
        </section>

    </>
  )
}

export default MorePackages

import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import {
  CustomerDomainName,
  ApiEndPoint,
  ActivityToken,
} from "../GlobalData/GlobalData";
import { Tooltip, Whisper } from "rsuite";
function ActivityCard({ newActivityListing }) {
  console.log(newActivityListing);
  const [isLoading, setIsLoading] = useState(false);
  var Domainpath = CustomerDomainName();
  var navigation = useNavigate();
  const showDetail = (id) => {
    navigation(`/activity_details/${id}`);
  };
  return (
    <>
      <div class="row">
        {newActivityListing.map((item, index) => (
          <div class="col-lg-12" key={index}>
            <div class="cruise_search_result_wrapper">
              <div class="cruise_search_item">
                <div class="row">
                  <div class="col-lg-4">
                    <div class="cruise_item_img">
                      <img
                        src={
                          Domainpath +
                          "/public/images/activites/" +
                          item.featured_image
                        }
                        alt="img"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div class="col-lg-8">
                    <div class="cruise_item_inner_content">
                      <div class="cruise_content_top_wrapper">
                        <div class="cruise_content_top_left">
                          <ul>
                            <li>
                              Activity Hours : {item.duration}{" "}
                              <i class="fas fa-circle"></i>
                            </li>
                            <li>Dates: {item.activity_date}</li>
                          </ul>
                          <div class="cruise_content_top_right">
                          <h5>
                            {item.starts_rating === "" ? (
                              <div class="cat_star">No Rating</div>
                            ) : (
                              <div class="cat_star">
                                {Array(item.starts_rating)
                                  .fill(0)
                                  .map((_, index) => (
                                    <i
                                      style={{ color: "#fa5636" }}
                                      key={index}
                                      className="fa fa-star"
                                    >
                                      {/* <FontAwesomeIcon icon={faStar} /> */}
                                    </i>
                                  ))}
                              </div>
                            )}
                          </h5>
                        </div>
                          <h4>
                            {" "}
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() => showDetail(item.id)}
                              className="hotel-card-name"
                            >
                              {" "}
                              {item.title}
                            </a>
                          </h4>
                          <p>
                            <i class="fas fa-map-marker-alt"></i>{" "}
                            {item.location}
                          </p>
                        </div>
                        
                      </div>
                      <div class="cruise_content_middel_wrapper">
                        <div class="cruise_content_middel_right">
                          <p>
                            from {item.currency_symbol} {item.sale_price}
                          </p>
                        </div>
                      </div>
                      <div class="cruise_content_bottom_wrapper">
                        <div class="cruise_content_bottom_left">
                          {item.activity_content
                            ? item.activity_content.slice(0, 300)
                            : ""}
                        </div>
                        <div class="cruise_content_bottom_right">
                          <a
                            onClick={() => showDetail(item.id)}
                            class="btn btn_theme btn_md"
                          >
                            View details
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default ActivityCard;

import React, { useEffect } from 'react'
import img2 from '../../Images/NewTemplate/bg_call_section.jpg'
import word from '../../img/icon/world.png'
import walte from '../../img/icon/walte.png'
import star from '../../img/icon/star.png'
import present from '../../img/icon/persentis.png'
import Layout from '../../Components/Layout/Layout'
import wow from 'wowjs'
import cimg from '../../img/banner/common-banner.png'
import { NavLink } from 'react-router-dom'

function About () {
  useEffect(() => {
    new wow.WOW().init()
  }, [])

  const sectionStyle = {
    background: `url(${cimg}) no-repeat`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height : "100%"

  }; 


  return (
    <>
      <Layout>
      <section id="common_banner" style={sectionStyle}>
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="common_bannner_text">
                        <h2>About</h2>
                        <ul>
                            <li><NavLink to="/">Home</NavLink></li>
                            <li><span><i class="fas fa-circle"></i></span>About</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section id="about_us_top" class="section_padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="about_us_left">
                        
                       
                        <p>At Hashim Travel Limited, our journey began with a simple yet powerful vision: to connect people with the wonders of travel. Founded in the heart of the UK, we have grown from a small local agency into a trusted partner for travelers seeking unique and unforgettable experiences. Our founders, driven by their passion for exploration, realized the need for personalized travel solutions that cater to every type of adventurer. </p>
                        <p>Over the years, Hashim Travel Limited has established a reputation for reliability and excellence in the travel industry. Our commitment to quality service and customer satisfaction has attracted a loyal clientele, and we take pride in being a part of countless memorable journeys. With a team of experienced travel experts, we are here to guide you through every step of your travel planning process, ensuring that your trips are not just vacations but lifelong memories. </p>
                        
                    </div>
                </div>
                <div class="col-lg-6 mt-5">
                    <div class="about_us_right">
                        <img src={img2} alt="img" />
                    </div>
                </div>

            </div>
        </div>
    </section>


    {/* <!-- About Service Area --> */}


    <section id="about_service_offer" class="section_padding_bottom">
        <div class="container">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="section_heading_center">
                    <h2>Why Choose Us</h2>
                </div>
            </div>
          
            <div class="row gy-4">
                <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="about_service_boxed">
                        <img src={word} alt="img" />
                        <h5><a href="#!">+ 1000 Customers</a></h5>
                        <p>Phaseus site amet tristique ligua donec iaculis leo sus cipit. Consec tetur adipiscing elit.
                            Incididunt ut dolore.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="about_service_boxed">
                        <img src={walte} alt="img" />
                        <h5><a href="#!">24/7 Support</a></h5>
                        <p>Phaseus site amet tristique ligua donec iaculis leo sus cipit. Consec tetur adipiscing elit.
                            Incididunt ut dolore.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="about_service_boxed">
                        <img src={star} alt="img" />
                        <h5><a href="#!">WorldWide Locations</a></h5>
                        <p>Phaseus site amet tristique ligua donec iaculis leo sus cipit. Consec tetur adipiscing elit.
                            Incididunt ut dolore.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="about_service_boxed">
                        <img src={present} alt="img" />
                        <h5><a href="#!">Help Direct Line</a></h5>
                        <p>Phaseus site amet tristique ligua donec iaculis leo sus cipit. Consec tetur adipiscing elit.
                            Incididunt ut dolore.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="about_service_boxed">
                        <img src={present} alt="img" />
                        <h5><a href="#!">Secure Payments</a></h5>
                        <p>Phaseus site amet tristique ligua donec iaculis leo sus cipit. Consec tetur adipiscing elit.
                            Incididunt ut dolore.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="about_service_boxed">
                        <img src={present} alt="img" />
                        <h5><a href="#!">Support via Chat</a></h5>
                        <p>Phaseus site amet tristique ligua donec iaculis leo sus cipit. Consec tetur adipiscing elit.
                            Incididunt ut dolore.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>





      </Layout>
    </>
  )
}

export default About

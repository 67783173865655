import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavLink } from 'react-router-dom'
import cimg from '../../img/banner/common-banner.png'
import {
  faCalendarAlt,
  faMapMarkerAlt
} from '@fortawesome/free-solid-svg-icons'
import Layout from '../../Components/Layout/Layout'
import bgimage from '../../Images/New/banner-search.jpg'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import Carousel from 'react-bootstrap/Carousel'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import Axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Loading from '../../Components/Loading/Loader'
import { ToastContainer, toast } from 'react-toastify'
import {
  ApiEndPoint,
  ActivityToken,
  CustomerDomainName
} from '../../Components/GlobalData/GlobalData'
import ImageGallery from 'react-image-gallery'
import ReactOwlCarousel from 'react-owl-carousel'
import ActivitySearch from '../../Components/SearchBar/ActivitySearch'
function ActivityDetail () {
  const navigate = useNavigate()
  const [activityDetail, setActivityDetail] = useState(null)
  const [whatExpect, setWhatExpect] = useState(null)
  const [faqs, setFaqs] = useState(null)
  const [availibalityDays, setAvailibalityDays] = useState(null)
  const [additionalServices, setAdditionalServices] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [activeIndex, setActiveIndex] = useState(null)
  const [activeIndex1, setActiveIndex1] = useState(null)
  const [selectedDate, setSelectedDate] = useState('')
  const [selectAdults, setSelectAdults] = useState('')
  const [selectChilds, setSelectChilds] = useState('')
  const [imageArray, setImageArray] = useState([])
  const [selectedServices, setSelectedServices] = useState([])
  const [isModal, setIsModal] = useState(false)
  const [isAvailableModal, setisAvailableModal] = useState(false)

  const DomainURL = CustomerDomainName()
  useEffect(() => {
    GetDetail()
  }, [])
  const options = {
    margin: 10,
    nav: true,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      1000: {
        items: 3
      }
    }
  }
  const GetDetail = async () => {
    var currentURL = window.location.href
    // Extract the last part of the URL
    var urlParts = currentURL.split('/')
    var lastPart = urlParts[urlParts.length - 1]

    var endpoint = ApiEndPoint()
    var token = ActivityToken()
    var data = {
      token: token,
      id: lastPart
    }
    setIsLoading(true)
    try {
      const response = await Axios.post(
        endpoint + '/api/activity-details-react',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      setIsLoading(false)
      setActivityDetail(response.data.data)
      setWhatExpect(JSON.parse(response.data.data.what_expect))
      setFaqs(JSON.parse(response.data.data.faqs_arr))
      setAdditionalServices(
        JSON.parse(response.data.data.addtional_service_arr)
      )
      setImageArray(JSON.parse(response.data.data.gallery_images))
      if (response !== undefined) {
        const start = new Date(response.data.data.start_date) // Example start date
        const end = new Date(response.data.data.end_date) // Example end date
        var resultDates = getDatesBetween(
          start,
          end,
          JSON.parse(response.data.data.Availibilty)
        )
        setAvailibalityDays(resultDates)
      }
      //   console.log(resultDates);
      // dispatch(ActivitiesListing(response.data));
      // navigation('/activities');
    } catch (error) {
      setIsLoading(false)
      console.error('Error:', error)
    }
  }
  const toggleModal = () => {
    setIsModal(!isModal)
  }
  const toggleAVailabilityModal = () => {
    setisAvailableModal(!isAvailableModal)
  }
  function getDatesBetween (start, end, daysArray) {
    const enabledDays = []
    const startDateNum = start.getDay()
    let currentDate = new Date(start)

    while (currentDate <= end) {
      const dayNum = currentDate.getDay()

      // Handle Sunday correctly:
      const dayData = dayNum === 0 ? daysArray['7'] : daysArray[dayNum]

      if (dayData && dayData.enable) {
        enabledDays.push({
          date: new Date(currentDate),
          from: dayData.from,
          to: dayData.to
        })
      }

      currentDate.setDate(currentDate.getDate() + 1)
    }
    return enabledDays
  }

  // Custom function to filter dates
  const filterDate = date => {
    // Return true if the date is in the enabledDates array, false otherwise
    return availibalityDays.some(
      enabledDate =>
        date.getDate() === enabledDate.date.getDate() &&
        date.getMonth() === enabledDate.date.getMonth() &&
        date.getFullYear() === enabledDate.date.getFullYear()
    )
  }

  const handleAdult = event => {
    setSelectAdults(event.target.value)
  }
  const handleChild = event => {
    setSelectChilds(event.target.value)
  }

  const SaveData = () => {
    
    if (selectedDate === '') {
      toast.info('Please select Activity date.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    }
    if (selectAdults === '' || selectAdults === '0') {
      toast.info('Please select Adult for Activity.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    }
    var newdata = {
      date: selectedDate,
      adults: selectAdults,
      childs: selectChilds,
      adultPrice: activityDetail?.sale_price,
      childrenPrice: activityDetail?.child_sale_price,
      currency: activityDetail?.currency_symbol
    }
    sessionStorage.setItem(
      'AdditionalServices',
      JSON.stringify(selectedServices)
    )
    sessionStorage.setItem('ActivityData', JSON.stringify(newdata))
    sessionStorage.setItem('ActivityDetail', JSON.stringify(activityDetail))

    navigate('/activity_checkout')
  }

  const handleCheckboxChange = (checkboxName, servicetype, serviceprice) => {
    const updatedCheckboxes = [...selectedServices]

    // Check if the checkbox is already in the array
    const existingCheckboxIndex = updatedCheckboxes.findIndex(
      item => item.name === checkboxName
    )

    if (existingCheckboxIndex !== -1) {
      // If it is, remove it
      updatedCheckboxes.splice(existingCheckboxIndex, 1)
    } else {
      // If it is not, add it
      updatedCheckboxes.push({
        name: checkboxName,
        type: servicetype,
        price: serviceprice
      })
    }

    setSelectedServices(updatedCheckboxes)
  }

  const handleAdditionalServicePerson = (event, serviceName) => {
    const newAdultValue = parseInt(event.target.value, 10) // Assuming you get the adult value from the event
    addAdultToService(serviceName, newAdultValue)
  }

  const addAdultToService = (serviceName, newAdultValue) => {
    setSelectedServices(prevServices => {
      return prevServices?.map(service => ({
        ...service,
        ...(service.name === serviceName ? { adult: newAdultValue } : {})
      }))
    })
  }

  const show = index => {
    const buttons = document.getElementsByClassName('accordion')
    const panel = buttons[index].nextElementSibling
    buttons[index].classList.toggle('active-2')

    if (panel.style.maxHeight) {
      panel.style.maxHeight = null
    } else {
      panel.style.maxHeight = panel.scrollHeight + 'px'
    }

    // Remove "active" class from other buttons
    for (let i = 0; i < buttons.length; i++) {
      if (i !== index) {
        buttons[i].classList.remove('active-2')
        buttons[i].nextElementSibling.style.maxHeight = null
      }
    }
  }
  const show1 = index => {
    const buttons = document.getElementsByClassName('accordion2')
    const panel = buttons[index].nextElementSibling
    buttons[index].classList.toggle('active-2')

    if (panel.style.maxHeight) {
      panel.style.maxHeight = null
    } else {
      panel.style.maxHeight = panel.scrollHeight + 'px'
    }

    for (let i = 0; i < buttons.length; i++) {
      if (i !== index) {
        buttons[i].classList.remove('active-2')
        buttons[i].nextElementSibling.style.maxHeight = null
      }
    }
  }
  const sectionStyle = {
    background: `url(${cimg}) no-repeat`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height : "100%"

  }; 
  return (
    <>
      <ToastContainer />
      {isLoading && <Loading />}
      <Layout>
        <main>
        <section id="common_banner" style={sectionStyle}>
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="common_bannner_text">
                        <h2> {activityDetail?.title}</h2>
                        <ul>
                        <li><NavLink to="/">Home</NavLink></li>
                            <li><span><i class="fas fa-circle"></i></span>{activityDetail?.location}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
       
          <div class='bg_color_1'>
            <div class='container margin_60_35'>
            {activityDetail?.video_link !==null && (
          <div className='text-center mt-3'>
          <iframe
            width="100%"
            height="380"
            src={activityDetail?.video_link}
            frameborder="0"
            allowfullscreen
          ></iframe>
        </div>
        )}
              <div class='row'>
                <div class='col-lg-8 tour_details_right_boxed'>
                  <section id='description tour_details_right_boxed'>
                    <h2>Description</h2>
                    <p>{activityDetail?.activity_content}</p>
                    <div class='row'>
                      <div class='col-lg-6'>
                        <h6>Included</h6>
                        <p>{activityDetail?.whats_included}</p>
                      </div>
                      <div class='col-lg-6'>
                        <h6>Excluded</h6>
                        <p> {activityDetail?.whats_excluded}</p>
                      </div>
                    </div>
                    <div class='g-tour-feature'>
                      <div class='row'>
                        <div class='col-xs-6 col-lg-3 col-6 col-md-6'>
                          <div class='item'>
                            <div class='info'>
                              <h6 class='name'>Duration</h6>
                              <p class='value'>
                                {activityDetail?.duration} Hour
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class='col-xs-6 col-lg-3 col-6  col-md-6'>
                          <div class='item'>
                            <div class='info'>
                              <h6 class='name'>Start Date</h6>
                              <p class='value'>
                                {moment(activityDetail?.start_date).format(
                                  'll'
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class='col-xs-6 col-lg-3 col-6  col-md-6'>
                          <div class='item'>
                            <div class='info'>
                              <h6 class='name'>Group Size</h6>
                              <p class='value'>
                                {activityDetail?.max_people} persons
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class='col-xs-6 col-lg-3 col-6  col-md-6'>
                          <div class='item'>
                            <div class='info'>
                              <h6 class='name'>Location</h6>
                              <p class='value'> {activityDetail?.location}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ width: '100%' }}>
                      <ImageGallery
                        showFullscreenButton={true}
                        items={imageArray.map(image => ({
                          original:
                            DomainURL + '/public/images/activites/' + image, // large image
                          thumbnail:
                            DomainURL + '/public/images/activites/' + image // thumbnail
                        }))}
                      />
                    </div>
                    {whatExpect !== null && whatExpect.length !== 0 && (
  <div>
    <hr />
    <h3>
      Program <small>({activityDetail?.duration} Hour)</small>
    </h3>
    <div className="accordion" id="programAccordion">
      {whatExpect.map((item, index) => (
        <div className="accordion-item" key={index}>
          <h2 className="accordion-header"id={`programHeading${index}`}>
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#programCollapse${index}`}
              aria-expanded="true"
              aria-controls={`programCollapse${index}`}
            >
              {index + 1}. {item.title}
            </button>
          </h2>
          <div
            id={`programCollapse${index}`}
            className="accordion-collapse collapse"
            aria-labelledby={`programHeading${index}`}
            data-bs-parent="#programAccordion"
          >
            <div className="accordion-body">
              <p>{item.expect_content}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
)}

                    {faqs !== null && faqs?.length !== 0 && (
                      <div>
                        <hr />
                        <h3> FAQs</h3>
                        <div className='accordion' id='accordionExample'>
                          {faqs.map((item, index) => (
                            <div className='accordion-item' key={index}>
                              <h2
                                className='accordion-header'
                                id={`heading${index}`}
                              >
                                <button
                                  className={`p-2 accordion-button ${
                                    index === 0 ? '' : 'collapsed'
                                  }`}
                                  type='button'
                                  data-bs-toggle='collapse'
                                  data-bs-target={`#collapse${index}`}
                                  aria-expanded={index === 0 ? 'true' : 'false'}
                                  aria-controls={`collapse${index}`}
                                >
                                  {item.title}
                                </button>
                              </h2>
                              <div
                                id={`collapse${index}`}
                                className={`accordion-collapse collapse ${
                                  index === 0 ? 'show' : ''
                                }`}
                                aria-labelledby={`faqHeading${index}`}
                                data-bs-parent='#accordionExample'
                              >
                                <div className='accordion-body'>
                                  <strong>{item.content}</strong>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </section>
                </div>



                <div class='col-lg-4' id='sidebar'>
                  <section id='description' className='tour_details_right_boxed'>
                    <h2>Availability</h2>
                    <div class='list-attributes'>
                      <table class='table'>
                        <thead>
                          <tr>
                            <th>Day of Week</th>
                            <th>Open</th>
                            <th>Close</th>
                          </tr>
                        </thead>
                        <tbody>
                          {availibalityDays?.slice(0, 12).map((item, index) => (
                            <tr key={index}>
                              <td>
                                {moment(item.date).format('dddd')} -{' '}
                                {moment(item.date).format('DD-MM-YYYY')}
                              </td>
                              <td>{item.from}</td>
                              <td>{item.to}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {availibalityDays !== null && (
                        <div>
                          {availibalityDays?.length > 12 && (
                            <p
                              onClick={toggleAVailabilityModal}
                              style={{ cursor: 'pointer' }}
                              className='text-center text-primary'
                            >
                              View All
                            </p>
                          )}
                        </div>
                      )}
                    </div>
                  </section>


                  <div class='box_detail booking tour_details_right_boxed mt-2'>
                    <h2>Select Activity</h2>
                    <div class='price'>
                      <div class='d-flex justify-content-between mt-2'>
                        <div>
                          <h6 class='card-title'>Start Date:</h6>
                        </div>
                        <div>
                          {' '}
                          {moment(activityDetail?.start_date).format(
                            'DD-MM-YYYY'
                          )}
                        </div>
                      </div>
                      <div class='d-flex justify-content-between mt-2'>
                        <div>
                          <h6 class='card-title'>End Date:</h6>
                        </div>
                        <div>
                          {' '}
                          {moment(activityDetail?.end_date).format(
                            'DD-MM-YYYY'
                          )}
                        </div>
                      </div>
                      <div class='d-flex justify-content-between mt-2'>
                        <div>
                          <h6 class='card-title'>Adult Price:</h6>
                        </div>
                        <h6>
                          {' '}
                          {activityDetail?.currency_symbol}{' '}
                          {activityDetail?.sale_price}
                        </h6>
                      </div>
                      {activityDetail?.child_sale_price !== null && (
                        <div class='d-flex justify-content-between mt-2'>
                          <div>
                            <h6 class='card-title'>Child Price:</h6>
                          </div>
                          <h6>
                            {' '}
                            {activityDetail?.currency_symbol}{' '}
                            {activityDetail?.child_sale_price}
                          </h6>
                        </div>
                      )}
                    </div>
                    {additionalServices !== null &&
                              additionalServices?.length !== 0 && (
                                <div className='mt-2'>
                                  <h6>Additional Services</h6>
                                </div>
                              )}
                            {additionalServices?.map((item, index) => (
                              <div key={index}>
                                <div className='btn btn_theme'>
                                  <label class='d-flex align-items-center'>
                                    <input
                                      id='0'
                                      autocomplete='off'
                                      checked={
                                        selectedServices.length > 0 &&
                                        selectedServices.some(
                                          data =>
                                            data.name === item.service_name
                                        )
                                      }
                                      onChange={() =>
                                        handleCheckboxChange(
                                          item.service_name,
                                          item.service_type,
                                          item.service_price
                                        )
                                      }
                                      class='room-check me-2'
                                      type='checkbox'
                                    />
                                    {item.service_name}
                                  </label>
                                </div>

                                <div class='d-flex justify-content-between mt-2'>
                                  <div>
                                    <h6 class='card-title'>Price:</h6>
                                  </div>
                                  <div>
                                    {' '}
                                    {activityDetail?.currency_symbol}{' '}
                                    {item.service_price}
                                  </div>
                                </div>
                                <div class='d-flex justify-content-between mt-2'>
                                  <div>
                                    <h6 class='card-title'>Service Type:</h6>
                                  </div>
                                  <div> {item.service_type}</div>
                                </div>
                                {item.service_type === 'Per Person' && (
                                  <div class='form-group mt-2'>
                                    <h6 class='card-title'>Select Person:</h6>
                                    <select
                                      value={
                                        selectedServices.length > 0
                                          ? selectedServices.find(
                                              data =>
                                                data.name === item.service_name
                                            )?.adult || '0'
                                          : '0'
                                      }
                                      onChange={event =>
                                        handleAdditionalServicePerson(
                                          event,
                                          item.service_name
                                        )
                                      }
                                      disabled={
                                        !(
                                          selectedServices.length > 0 &&
                                          selectedServices.some(
                                            data =>
                                              data.name === item.service_name
                                          )
                                        )
                                      }
                                      className='form-control bg_input form-select select-styling'
                                      id='exampleFormControlSelect1'
                                    >
                                      <option selected value='0'>
                                        0
                                      </option>
                                      {Array.from(
                                        { length: selectAdults },
                                        (_, index) => index + 1
                                      )?.map(optionValue => (
                                        <option
                                          key={optionValue}
                                          value={optionValue}
                                        >
                                          {optionValue}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                )}
                              </div>
                            ))}
                    <div className='date_input_width my-1'>
                      <DatePicker
                        selected={selectedDate}
                        onChange={date => setSelectedDate(date)}
                        placeholderText='Select Date'
                        filterDate={filterDate}
                        minDate={new Date()}
                        dateFormat='dd/MM/yyyy' // Customize date format as needed
                        className='form-control  bg_input w-100 text-start '
                      />
                    </div>
                    <div class='date-wrapper clearfix pb-0 px-0 my-1' v-else>
                      <div class='check-in-wrapper'>
                        <label>Adult</label>
                        <div class='render check-in-render'>
                          <select
                            onChange={handleAdult}
                            value={selectAdults}
                            className='form-control bg_input form-select'
                            id='exampleFormControlSelect1'
                          >
                            <option selected value='0'>
                              0
                            </option>
                            <option value='1'>1</option>
                            <option value='2'>2</option>
                            <option value='3'>3</option>
                            <option value='4'>4</option>
                            <option value='5'>5</option>
                            <option value='6'>6</option>
                            <option value='7'>7</option>
                            <option value='8'>8</option>
                            <option value='9'>9</option>
                            <option value='10'>10</option>
                          </select>
                        </div>
                      </div>
                     
                    </div>
                    <div class='date-wrapper clearfix pb-0 my-1 px-0' v-else>
                      <div class='check-in-wrapper'>
                        <label>Child</label>
                        <div class='render check-in-render'>
                          <select
                            value={selectChilds}
                            onChange={handleChild}
                            disabled={activityDetail?.child_sale_price == null}
                            className='form-control bg_input form-select select-styling'
                            id='exampleFormControlSelect1'
                          >
                            <option selected value='0'>
                              0
                            </option>
                            <option value='1'>1</option>
                            <option value='2'>2</option>
                            <option value='3'>3</option>
                            <option value='4'>4</option>
                            <option value='5'>5</option>
                            <option value='6'>6</option>
                            <option value='7'>7</option>
                            <option value='8'>8</option>
                            <option value='9'>9</option>
                            <option value='10'>10</option>
                          </select>
                        </div>
                      </div>
                      
                    </div>

                    <a onClick={SaveData} class='btn_theme btn'>
                      Book Now
                    </a>

                    <div
                      onClick={toggleModal}
                      class='text-center text-primary '
                      style={{ cursor: 'pointer' }}
                    >
                      <small>Cancellation Policy ?</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Modal isOpen={isModal} className='t-0' toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>Cancellation Policy</ModalHeader>
          <ModalBody>
            <div className='form-group'>
              <p>{activityDetail?.cancellation_policy}</p>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={isAvailableModal}
          className='custom-modal t-0'
          toggle={toggleAVailabilityModal}
        >
          <ModalHeader toggle={toggleAVailabilityModal}>
            Availability
          </ModalHeader>
          <ModalBody>
            <table class='table'>
              <thead>
                <tr>
                  <th>Day of Week</th>
                  <th>Open</th>
                  <th>Close</th>
                </tr>
              </thead>
              <tbody>
                {availibalityDays?.map((item, index) => (
                  <tr key={index}>
                    <td>
                      {moment(item.date).format('dddd')} -{' '}
                      {moment(item.date).format('DD-MM-YYYY')}
                    </td>
                    <td>{item.from}</td>
                    <td>{item.to}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </ModalBody>
        </Modal>
      </Layout>
    </>
  )
}

export default ActivityDetail

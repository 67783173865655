import React,{useState,useEffect} from "react";
// import img1 from '../../Images/Home/makkah.jpg';
// import img2 from '../../Images/Home/madinah.jpg';
// import img3 from '../../Images/Home/jeddah.jpeg';
// import img4 from '../../Images/Home/riyadh.jpg';
import imgg4 from '../../Images/Home/4.jpg'
import imgg5 from '../../Images/Home/5.jpg'
import imgg6 from '../../Images/Home/6.jpg'
import { ApiEndPoint,ActivityToken,Hotelapitoken } from "../../Components/GlobalData/GlobalData";
import moment from "moment";
import Axios from "axios";
import Loading from "../../Components/Loading/Loader";
import { ActivitiesListing,fetchHotelsSearh,fetchHotels } from "../../Redux/Actions/actions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import IndexComponents from "./IndexComponents";
function BestCollection(){
  const [isLoading, setIsLoading] = useState(false);
  const navigation=useNavigate();
  const dispatch=useDispatch();
  var endpoint=ApiEndPoint();
  const showExcursions=async(location)=>{
    var token=ActivityToken();
    const currentdate=new Date();
    var data={
      'token':token,
      'location':location,
      'start_dates':moment(currentdate).format('DD-MM-YYYY'),
    }
    setIsLoading(true);
    try {
      
      const response = await Axios.post(endpoint+'/api/search_activities_react',data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        } ,
        
      });
      setIsLoading(false);
      dispatch(ActivitiesListing(response.data));
      navigation('/activities');
    
    } catch (error) {
      // Handle errors here
      setIsLoading(false);
      console.error('Error:', error);
    }
    
  };


  const Searchhotels = async (name) => {
    //  setIsLoading(true);
     var token2=Hotelapitoken();
     const currentDate = new Date();
     const twoDaysLater = new Date(currentDate);
     twoDaysLater.setDate(currentDate.getDate() + 2);
     const threeDaysLater = new Date(currentDate);
     threeDaysLater.setDate(currentDate.getDate() + 3);
     var destination_name='';
      var country='';
      var lat='';
      var lon='';
      var pin='';
     if(name==='makkah'){
      destination_name="Makkah";
      country="Saudi Arabia";
      lat=21.478659;
      lon=39.81733639999999;
      pin="SA";
     }else if(name==='madinah'){
      destination_name="Madinah";
      country="Saudi Arabia";
      lat=24.4672132;
      lon=39.6024496;
      pin="SA";
     }else if(name==='jeddah'){
      destination_name="Jeddah";
      country="Saudi Arabia";
      lat=21.5291545;
      lon=39.1610863;
      pin="SA";
     }else if(name==='riyadh'){
      destination_name="Riyadh";
      country="Saudi Arabia";
      lat=24.7135517;
      lon=46.6752957;
      pin="SA";
     }
      
     // Define your API URL, authToken, and dataToSend as shown in the previous example
     const FormData = {
      
       "destination_name":destination_name,
       "country":country,
       "lat":  lat,
       "long": lon,
       "pin": pin,
       "cityd":destination_name,
       "country_code": pin,
       "check_in":moment(twoDaysLater).format('YYYY-MM-DD'),
       "check_out":moment(threeDaysLater).format('YYYY-MM-DD'),
       "slc_nationality": "Pakistan",
       "adult": 2,
       "child": 0,
       "room": 1,
       "Adults": [2],
       "children": [0],
       "child_ages1": [],
       "rooms_counter": [1],
       "child_ages2": [],
      
     };
     const queryString = new URLSearchParams(FormData).toString();
     navigation(`/hotels?${queryString}`);
     return
     try {
     
      dispatch(fetchHotelsSearh(FormData));
       const response = await Axios.post(endpoint+'/api/search/hotels/new_Live',FormData, {
         headers: {
           "Access-Control-Allow-Origin": "*",
         
         } ,
         
       });
       sessionStorage.removeItem('FlightCheckOut');
       navigation('/hotels');
       dispatch(fetchHotels(response.data));
       // Handle the API response here
       setIsLoading(false);
     } catch (error) {
       // Handle errors here
       setIsLoading(false);
       console.error('Error:', error);
     }
   };

    return(
        <>
        {isLoading && (
          <Loading/>
        )}



        {/* <div className="p-3 Best-collection1">
            <h3 style={{color:'green'}}>Explore Saudi Arabia</h3>
            <div class="container-fluid mt-4">
              <div class="row">
                <div class="col-md-3 mb-4">
                    <div  style={{position:'relative'}}>
                      <img src={img1} class="img-fluid rounded-top portrait-image" alt="Image 1"/>
                      <div class="img-caption mb-3" >
                        <h4>Makkah</h4>
                      </div>
                      <div className="d-flex m-1 img-btn-city">
                          <button onClick={()=>Searchhotels('makkah')} className="btn w-100 mt-2 btn-success">Hotels</button>
                          <button className="btn w-100 mt-2 btn-success">Excursions</button>
                        </div>
                    </div>
                  </div>
                  <div class="col-md-3 mb-4">
                <div  style={{position:'relative'}}>
                  <img src={img2} class="img-fluid rounded-top portrait-image" alt="Image 2"/>
                  <div class="img-caption mb-3" >
                  <h4>Medina</h4>
                  </div>
                  <div className="d-flex m-1 img-btn-city">

                          <button onClick={()=>Searchhotels('madinah')} className="btn w-100 mt-2 btn-success">Hotels</button>
                          <button className="btn w-100 mt-2 btn-success">Excursions</button>
                        </div>
                </div>
                
                  </div>
                  <div class="col-md-3 mb-4">
                <div  style={{position:'relative'}}>
                  <img src={img3} class="img-fluid rounded-top portrait-image" alt="Image 3"/>
                  <div class="img-caption mb-3" >
                
                  <h4>Jeddah</h4>
                  </div>
                  <div className="d-flex m-1 img-btn-city">
                          <button onClick={()=>Searchhotels('jeddah')} className="btn w-100 mt-2 btn-success">Hotels</button>
                          <button className="btn w-100 mt-2 btn-success">Excursions</button>
                        </div>
                </div>
              
                  </div>
                  <div class="col-md-3 mb-4">
                <div  style={{position:'relative'}}>
                  <img src={img4} class="img-fluid rounded-top portrait-image" alt="Image 4" />
                  <div class="img-caption mb-3" >
                    <h4>Riyadh</h4>
                  </div>
                  <div className="d-flex m-1 img-btn-city">
                          <button onClick={()=>Searchhotels('riyadh')} className="btn w-100 mt-2 btn-success">Hotels</button>
                          <button onClick={()=>showExcursions('Riyadh Saudi-Arabië')} className="btn w-100 mt-2 btn-success">Excursions</button>
                        </div>
                </div>
              
                  </div>
              </div>
            </div>
        </div> */}




        <section id="top_destinations" class="section_padding_top">
          <div class="container">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                
                  <h2 className="text-center mb-5" style={{color:"#EA0876"}}>See Saudia in a Variety of Ways</h2>
                
              </div>
            </div>

            <div class="row align-items-center">
              <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                <div class="imagination_boxed">
                  <a>
                    <img src={imgg4} alt="img" />
                  </a>
                  <h3>
                    <a className="text-light" onClick={()=>Searchhotels('makkah')} style={{cursor:"pointer"}}>
                      Makkah
                    </a>
                  </h3>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                <div class="imagination_boxed">
                  <a >
                    <img src={imgg5} alt="img" />
                  </a>
                  <h3>
                    <a className="text-light" onClick={()=>Searchhotels('madinah')} style={{cursor:"pointer"}}>
                      Madinah
                    </a>
                  </h3>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                <div class="imagination_boxed">
                  <a>
                    <img src={imgg6} alt="img" />
                  </a>
                  <h3>
                    <a className="text-light" onClick={()=>Searchhotels('jeddah')} style={{cursor:"pointer"}}>
                      Jeddah
                    </a>
                  </h3>
                </div>
              </div>
              
            </div>
            <IndexComponents />
          </div>
        </section>

        </>
    );
}

export default BestCollection;
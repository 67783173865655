import React, { useState } from 'react'
import Layout from '../../Components/Layout/Layout'
import bgimage from '../../Images/New/banner-search.jpg'
import cimg from '../../img/banner/common-banner.png'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faStar,
  faAngleDown,
  faAngleUp
} from '@fortawesome/free-solid-svg-icons'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import ActivityCard from '../../Components/Activity/ActivityCard'
import { useSelector } from 'react-redux'
import ActivitySearch from '../../Components/SearchBar/ActivitySearch'
function Activities () {
  const [ShowRatingFilter, setShowRatingFilter] = useState(false)
  const [starRating, setStarRating] = useState({
    rating5: '0',
    rating4: '0',
    rating3: '0',
    rating2: '0',
    rating1: '0',
    type: 'Stars'
  })
  const ActivityListing = useSelector(state => state.hotels.ActivityListing)
  const [newActivityListing, setNewActivityListing] = useState(
    ActivityListing.tours
  )
  const [FilterActivityListing, setFilterActivityListing] = useState(
    ActivityListing.tours
  )
  const handleCheckboxChange = event => {
    const { name, value } = event.target

    setStarRating(prevDetails => {
      const updatedStarRating = { ...prevDetails }
      if (updatedStarRating[name] !== '0') {
        updatedStarRating[name] = '0'
      } else {
        updatedStarRating[name] = value
      }

      return updatedStarRating
    })
  }

  const filterbyStars = () => {
    setShowRatingFilter(false)
    if (
      starRating.rating1 === '0' &&
      starRating.rating2 === '0' &&
      starRating.rating3 === '0' &&
      starRating.rating4 === '0' &&
      starRating.rating5 === '0'
    ) {
      setFilterActivityListing(newActivityListing)
    } else {
      console.log(newActivityListing)
      const filteredTours = newActivityListing.filter(tour => {
        const hotelRating = tour.starts_rating
        if (hotelRating !== '') {
          return Object.keys(starRating).some(
            ratingKey => Number(hotelRating) === Number(starRating[ratingKey])
          )
        }
      })
      setFilterActivityListing(filteredTours)
    }
  }

  const ToggleRatingFilter = () => {
    setShowRatingFilter(!ShowRatingFilter)
  }

  const sectionStyle = {
    background: `url(${bgimage}) no-repeat`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height : "100%"

  }; 
  return (
    <>
      <Modal isOpen={ShowRatingFilter} toggle={ToggleRatingFilter}>
        <ModalHeader toggle={ToggleRatingFilter}>
          Star Rating Filter
        </ModalHeader>
        <ModalBody>
          <div>
            <ul>
              <li>
                <label>
                  <input
                    type='checkbox'
                    className='custom-textbox'
                    onChange={handleCheckboxChange}
                    name='rating5'
                    value='5'
                  />
                  <i className='awe-icon awe-icon-check'></i>
                  <span className='rating'>
                    <i className='fa fa-star'>
                      {' '}
                      <FontAwesomeIcon icon={faStar} />
                    </i>
                    <i className='fa fa-star'>
                      {' '}
                      <FontAwesomeIcon icon={faStar} />
                    </i>
                    <i className='fa fa-star'>
                      {' '}
                      <FontAwesomeIcon icon={faStar} />
                    </i>
                    <i className='fa fa-star'>
                      {' '}
                      <FontAwesomeIcon icon={faStar} />
                    </i>
                    <i className='fa fa-star'>
                      {' '}
                      <FontAwesomeIcon icon={faStar} />
                    </i>
                  </span>
                </label>
              </li>
              <li>
                <label>
                  <input
                    type='checkbox'
                    className='custom-textbox'
                    onChange={handleCheckboxChange}
                    name='rating4'
                    value='4'
                  />
                  <i className='awe-icon awe-icon-check'></i>
                  <span className='rating'>
                    <i className='fa fa-star'>
                      {' '}
                      <FontAwesomeIcon icon={faStar} />
                    </i>
                    <i className='fa fa-star'>
                      {' '}
                      <FontAwesomeIcon icon={faStar} />
                    </i>
                    <i className='fa fa-star'>
                      {' '}
                      <FontAwesomeIcon icon={faStar} />
                    </i>
                    <i className='fa fa-star'>
                      {' '}
                      <FontAwesomeIcon icon={faStar} />
                    </i>
                  </span>
                </label>
              </li>
              <li>
                <label>
                  <input
                    type='checkbox'
                    className='custom-textbox'
                    onChange={handleCheckboxChange}
                    name='rating3'
                    value='3'
                  />
                  <i className='awe-icon awe-icon-check'></i>
                  <span className='rating'>
                    <i className='fa fa-star'>
                      {' '}
                      <FontAwesomeIcon icon={faStar} />
                    </i>
                    <i className='fa fa-star'>
                      {' '}
                      <FontAwesomeIcon icon={faStar} />
                    </i>
                    <i className='fa fa-star'>
                      {' '}
                      <FontAwesomeIcon icon={faStar} />
                    </i>
                  </span>
                </label>
              </li>
              <li>
                <label>
                  <input
                    type='checkbox'
                    className='custom-textbox'
                    onChange={handleCheckboxChange}
                    name='rating2'
                    value='2'
                  />
                  <i className='awe-icon awe-icon-check'></i>
                  <span className='rating'>
                    <i className='fa fa-star'>
                      {' '}
                      <FontAwesomeIcon icon={faStar} />
                    </i>
                    <i className='fa fa-star'>
                      {' '}
                      <FontAwesomeIcon icon={faStar} />
                    </i>
                  </span>
                </label>
              </li>
              <li>
                <label>
                  <input
                    type='checkbox'
                    className='custom-textbox'
                    onChange={handleCheckboxChange}
                    name='rating1'
                    value='1'
                  />
                  <i className='awe-icon awe-icon-check'></i>
                  <span className='rating'>
                    <i className='fa fa-star'>
                      {' '}
                      <FontAwesomeIcon icon={faStar} />
                    </i>
                  </span>
                </label>
              </li>
            </ul>
            <button onClick={filterbyStars} className='btn btn-warning m-2'>
              Apply
            </button>
          </div>
        </ModalBody>
      </Modal>

      <Layout>
      <main>
      <section id="common_banner" style={sectionStyle}>
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="common_bannner_text">
                        <h2>Activities Search Results</h2>
                        <ul>
                        <li><NavLink to="/">Home</NavLink></li>
                            <li><span><i class="fas fa-circle"></i></span>Activities</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section id="explore_area" class="section_padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-3">
                    <div class="left_side_search_area">
                        <div class="left_side_search_boxed">
                            <div class="left_side_search_heading">
                                <h5>Filter by hotel star</h5>
                            </div>
                            <div class="filter_review">
                              
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" onChange={handleCheckboxChange} name='rating5' value='5' />
                                        <label class="form-check-label" for="flexCheckDefaulta">
                                            <i class="fas fa-star color_theme"></i>
                                            <i class="fas fa-star color_theme"></i>
                                            <i class="fas fa-star color_theme"></i>
                                            <i class="fas fa-star color_theme"></i>
                                            <i class="fas fa-star color_theme"></i>
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox"  onChange={handleCheckboxChange} name='rating4' value='4' />
                                        <label class="form-check-label" for="flexCheckDefaulf21">
                                            <i class="fas fa-star color_theme"></i>
                                            <i class="fas fa-star color_theme"></i>
                                            <i class="fas fa-star color_theme"></i>
                                            <i class="fas fa-star color_theme"></i>
                                            <i class="fas fa-star color_asse"></i>
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox"onChange={handleCheckboxChange} name='rating3' value='3' />
                                        <label class="form-check-label" for="flexCheckDefaultf3">
                                            <i class="fas fa-star color_theme"></i>
                                            <i class="fas fa-star color_theme"></i>
                                            <i class="fas fa-star color_theme"></i>
                                            <i class="fas fa-star color_asse"></i>
                                            <i class="fas fa-star color_asse"></i>
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" onChange={handleCheckboxChange} name='rating2' value='2' />
                                        <label class="form-check-label" for="flexCheckDefaultf4">
                                            <i class="fas fa-star color_theme"></i>
                                            <i class="fas fa-star color_theme"></i>
                                            <i class="fas fa-star color_asse"></i>
                                            <i class="fas fa-star color_asse"></i>
                                            <i class="fas fa-star color_asse"></i>
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" onChange={handleCheckboxChange} name='rating1' value='1' />
                                        <label class="form-check-label" for="flexCheckDefaultf5">
                                            <i class="fas fa-star color_theme"></i>
                                            <i class="fas fa-star color_asse"></i>
                                            <i class="fas fa-star color_asse"></i>
                                            <i class="fas fa-star color_asse"></i>
                                            <i class="fas fa-star color_asse"></i>
                                        </label>
                                    </div>
                                    <button
                        onClick={filterbyStars}
                        type='submit'
                        class='btn btn_theme w-100 mt-2'
                      >
                        APPLY
                      </button>
                             
                            </div>
                        </div>

                   
                
                    </div>
                </div>

                <div class="col-lg-9">
                <ActivityCard newActivityListing={FilterActivityListing} />
                </div>
            </div>
        </div>
    </section>


          {/* <div class='container margin_60_35'>
            <div class='row'>
              <aside class='col-lg-3' id='sidebar'>
                <div id='filters_col'>
                  <a
                    data-bs-toggle='collapse'
                    href='#collapseFilters'
                    aria-expanded='false'
                    aria-controls='collapseFilters'
                    id='filters_col_bt'
                  >
                    Filters{' '}
                  </a>
                  <div class='collapse show' id='collapseFilters'>
                    <div class='filter_type'>
                      <h6>Star Rating</h6>
                      <ul>
                        <li>
                          <label class='container_check'>
                            <span class='cat_star'>
                              {' '}
                              <FontAwesomeIcon icon={faStar} />{' '}
                              <FontAwesomeIcon icon={faStar} />{' '}
                              <FontAwesomeIcon icon={faStar} />{' '}
                              <FontAwesomeIcon icon={faStar} />{' '}
                              <FontAwesomeIcon icon={faStar} />
                            </span>
                            <input
                              type='checkbox'
                              name='rating5'
                              onChange={handleCheckboxChange}
                              value='5'
                            />
                            <span class='checkmark'></span>
                          </label>
                        </li>
                        <li>
                          <label class='container_check'>
                            <span class='cat_star'>
                              <FontAwesomeIcon icon={faStar} />{' '}
                              <FontAwesomeIcon icon={faStar} />{' '}
                              <FontAwesomeIcon icon={faStar} />{' '}
                              <FontAwesomeIcon icon={faStar} />
                            </span>
                            <input
                              type='checkbox'
                              name='rating4'
                              onChange={handleCheckboxChange}
                              value='4'
                            />
                            <span class='checkmark'></span>
                          </label>
                        </li>
                        <li>
                          <label class='container_check'>
                            <span class='cat_star'>
                              <FontAwesomeIcon icon={faStar} />{' '}
                              <FontAwesomeIcon icon={faStar} />{' '}
                              <FontAwesomeIcon icon={faStar} />
                            </span>
                            <input
                              type='checkbox'
                              name='rating3'
                              onChange={handleCheckboxChange}
                              value='3'
                            />
                            <span class='checkmark'></span>
                          </label>
                        </li>
                        <li>
                          <label class='container_check'>
                            <span class='cat_star'>
                              {' '}
                              <FontAwesomeIcon icon={faStar} />{' '}
                              <FontAwesomeIcon icon={faStar} />
                            </span>
                            <input
                              type='checkbox'
                              name='rating2'
                              onChange={handleCheckboxChange}
                              value='2'
                            />
                            <span class='checkmark'></span>
                          </label>
                        </li>
                        <li>
                          <label class='container_check'>
                            <span class='cat_star'>
                              {' '}
                              <FontAwesomeIcon icon={faStar} />
                            </span>
                            <input
                              type='checkbox'
                              name='rating1'
                              onChange={handleCheckboxChange}
                              value='1'
                            />
                            <span class='checkmark'></span>
                          </label>
                        </li>
                      </ul>
                      <button
                        onClick={filterbyStars}
                        type='submit'
                        class='btn btn-primary w-100 mt-2'
                      >
                        APPLY
                      </button>
                    </div>
                  </div>
                </div>
              </aside>
              <div class="col-lg-9" id="list_sidebar">
              <ActivityCard newActivityListing={FilterActivityListing} />
              </div>
            </div>
          </div> */}
        </main>
      </Layout>
    </>
  )
}

export default Activities

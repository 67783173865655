import React,{useState,useEffect} from "react";
import { NavLink, useNavigate } from 'react-router-dom'
import moment from 'moment'
import logo from '../../Images/footer_logo.png'
import emaill from "../../img/common/email.png";
function Footer () {
  const navigate=useNavigate();
  const [userLocation,setUserLocation]=useState([]);
  useEffect(()=>{
    getUserLocation();
  },[]);
  function getUserLocation() {
    // Fetch user's location data
    fetch("https://api.geoapify.com/v1/ipinfo?&apiKey=0708571ae58c4688824a7d01ca397041")
      .then(response => response.json())
      .then(data => {
        setUserLocation(data);
        // Handle location data as needed
      })
      .catch(error => {
        console.error('Error fetching user location:', error);
      });
  }

  const Searchhotels = async (name) => {
    const currentDate = new Date();
    const twoDaysLater = new Date(currentDate);
    twoDaysLater.setDate(currentDate.getDate() + 2);
    const threeDaysLater = new Date(currentDate);
    threeDaysLater.setDate(currentDate.getDate() + 3);
    var destination_name='';
     var country='';
     var lat='';
     var lon='';
     var pin='';
    if(name==='Makkah'){
     destination_name="Makkah";
     country="Saudi Arabia";
     lat=21.4240968;
     lon=39.81733639999999;
     pin="SA";
    }else if(name==='Madinah'){
     destination_name="Madinah";
     country="Saudi Arabia";
     lat=24.4672132;
     lon=39.6024496;
     pin="SA";
    }else if(name==='Riyadh'){
     destination_name="Riyadh";
     country="Saudi Arabia";
     lat=24.7135517;
     lon=46.6752957;
     pin="SA";
    }else if(name==='Jeddah'){
      destination_name="Jeddah";
      country="Saudi Arabia";
      lat=21.5291545;
      lon=39.1610863;
      pin="SA";
     }
    // Define your API URL, authToken, and dataToSend as shown in the previous example
    const FormData = {
      "destination_name":destination_name,
      "country":country,
      "lat":  lat,
      "long": lon,
      "pin": pin,
      "cityd":destination_name,
      "country_code": pin,
      "check_in":moment(twoDaysLater).format('YYYY-MM-DD'),
      "check_out":moment(threeDaysLater).format('YYYY-MM-DD'),
      "slc_nationality":userLocation.country.name,
      "adult": 2,
      "child": 0,
      "room": 1,
      "Adults": [2],
      "children": [0],
      "child_ages1": [],
      "rooms_counter": [1],
      "child_ages2": [],
     
    };
    const queryString = new URLSearchParams(FormData).toString()
    navigate(`/hotels?${queryString}`)
  };
  return (
    <>

   {/* -----------------subscribe---------------- */}

   <section id="cta_area" className="mt-4">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-7">
                <div class="cta_left">
                  <div class="cta_icon">
                    <img src={emaill} alt="icon" />
                  </div>
                  <div class="cta_content">
                    <h4>Get the latest news and offers</h4>
                    <h2>Subscribe to our newsletter</h2>
                  </div>
                </div>
              </div>
              <div class="col-lg-5">
                <div class="cat_form">
                  <form id="cta_form_wrappper">
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter your mail address"
                      />
                      <button class="btn btn_theme btn_md" type="button">
                        Subscribe
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
    <footer id="footer_area">
        <div class="container">
            <div class="row">

                <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                    <div class="footer_heading_area">
                        <h5 className="fw-bold">Contacts</h5>
                    </div>
                    <div class="footer_first_area">
                        <div class="footer_inquery_area">
                            <h5>Call 24/7 for any help</h5>
                            <h3 > <a href="tel://07877285517" style={{color:"#EA0876", textDecoration:"none"}}>07877 285517</a></h3>
                        </div>
                        <div class="footer_inquery_area">
                            <h5>Mail to our support team</h5>
                            <h3> <a href="mailto:hashimtravel@hotmail.co.uk" style={{color:"#EA0876"}}>hashimtravel@hotmail.co.uk</a></h3>
                        </div>
                        <div class="footer_inquery_area">
                            <h5>Follow us on</h5>
                            <ul class="soical_icon_footer">
                                <li><a href="/" style={{color:"#EA0876"}}><i class="fab fa-facebook"></i></a></li>
                                <li><a href="/"style={{color:"#EA0876"}}><i class="fab fa-twitter-square"></i></a></li>
                                <li><a href="/" style={{color:"#EA0876"}}><i class="fab fa-instagram"></i></a></li>
                                <li><a href="/" style={{color:"#EA0876"}}><i class="fab fa-linkedin"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 offset-lg-1 col-md-6 col-sm-6 col-12">
                    <div class="footer_heading_area">
                        <h5 className="fw-bold">Company</h5>
                    </div>
                    <div class="footer_link_area">
                        <ul>
                            <li><NavLink to="/">Home</NavLink></li>
                            <li><NavLink to="/about-us">About Us</NavLink></li>
                            <li><NavLink to="/contact-us">Contact Us</NavLink></li>
                            
                        </ul>
                    </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                    <div class="footer_heading_area">
                        <h5 className="fw-bold">About Hashim Travel</h5>
                    </div>
                    <div class="footer_link_area">
                    <p>
                Hashim Travel Limited is the leading tour operator in the
                United Kingdom specializing in Hajj and Umrah services. Take
                advantage of our exceptional Hajj and Umrah packages,
                thoughtfully designed to meet the unique needs of pilgrims
                embarking on their significant sacred journey to Saudi Arabia.
              </p>
                    </div>
                </div>
               
              
            </div>
        </div>
    </footer>



    <div class="copyright_area">
        <div class="container">
            <div class="row align-items-center">
                <div class="co-lg-6 col-md-6 col-sm-12 col-12">
                    <div class="copyright_left">
                        <p>© {moment().format('YYYY')} Hashim Travel Limited All Rights Reserved.</p>
                    </div>
                </div>


                <div class="co-lg-6 col-md-6 col-sm-12 col-12">
                <ul id="additional_links">
						<li><NavLink to="/terms_and_conditions">Terms and conditions</NavLink></li>
						<li><NavLink to="/privacy_policy">Privacy</NavLink></li>
            <li><NavLink to="/complaint_policy">Complaint Policy</NavLink></li>
					
					</ul> 
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default Footer
